/* You can add global styles to this file, and also import other style files */

@import "assets/fonts/style.css";
body {
  font-family: "Gilroy-Medium ☞";
  font-weight: normal;
  font-style: normal;
  overflow-x: hidden;
  font-size: 14px;
  color: #484747;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0px;
  padding: 0px;
  font-weight: normal;
}

ul {
  margin: 0px;
  padding: 0px;
  list-style: none;
}

a {
  text-decoration: none !important;
  outline: none !important;
}

.d-flex-new {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  flex-wrap: wrap;
}

.d-flex-align {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  flex-wrap: wrap;
}

.d-align-new {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  flex-wrap: wrap;
}

a {
  transition: all 0.3s linear;
}

/*------------------ Home Page Css Start ------------------*/
header {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  padding: 13px 0px;
}

.logo a {
  display: block;
}

.logo img {
  max-width: 100%;
}

nav li {
  display: inline-block;
}

nav li a {
  color: #ffffff;
  font-weight: 500;
  margin-right: 45px;
}

nav li a:hover {
  color: #ff5757;
}

.header_login {
  margin-left: 15px;
}

.header_login a {
  color: #ffffff;
  font-weight: 500;
  position: relative;
}

.header_login a:hover {
  color: #ff5757;
}

.header_login a:after {
  position: absolute;
  content: "";
  height: 52px;
  width: 2px;
  background: #292e38;
  left: -29px;
  top: -14px;
}

.banner {
  background: url(./assets/images/banner_bg.jpg);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top center;
  min-height: 113vh;
  text-align: center;
  padding-top: 10%;
}

.banner h1 {
  font-weight: bold;
  color: #ffffff;
  font-size: 56px;
  line-height: 56px;
}

.banner p {
  display: inline-block;
  font-size: 20px;
  color: #ffffff;
  font-weight: bold;
  background: #ff5757;
  padding: 8px 17px;
  margin-top: 20px;
}

.sign_me_up {
  max-width: 810px;
  background: #fff;
  padding: 40px 55px;
  box-shadow: 10px 0px 50px rgba(0, 0, 0, 0.1);
  margin: -115px auto 0px;
  border-radius: 30px;
}

.sign_me_up p {
  width: 395px;
  font-weight: 300;
  color: #2f2e2e;
  font-size: 20px;
  line-height: 30px;
}

.btn {
  -webkit-box-shadow: none !important;
  -moz-box-shadow: none !important;
  box-shadow: none !important;
}

.btn-blue {
  background: #ff5757;
  color: #fff !important;
  text-transform: uppercase;
  font-size: 15px;
  font-weight: 500;
  padding: 13px 23px;
  border-radius: 5px;
  border: 0px;
}

.btn-blue:hover {
  background: #0229f7;
}

.how_it_works {
  margin-top: 55px;
}

.title {
  margin-bottom: 30px;
}

.title h3 {
  font-weight: 600;
  font-size: 32px;
  color: #2d303f;
}

.work_step {
  margin-top: 50px;
}

.work_step ul {
  position: relative;
}

.work_step ul:after {
  position: absolute;
  content: "";
  height: 2px;
  width: 100%;
  border-bottom: 2px dashed rgba(74, 156, 239, 0.5);
}

.work_step li {
  text-align: center;
  position: relative;
  z-index: 1;
}

.work_step_round_outer {
  position: relative;
  width: 111px;
  height: 111px;
  padding: 9px;
  margin: auto;
}

.work_round_bottom {
  background: url(./assets/images/round_bottom.png) #fff;
  background-position: bottom center;
  background-repeat: no-repeat;
}

.work_round_top {
  background: url(./assets/images/round_top.png) #fff;
  background-position: top center;
  background-repeat: no-repeat;
}

.work_step_round {
  height: 93px;
  width: 93px;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #f2f2f2;
  border-radius: 50%;
  position: relative;
}

.work_step li p {
  font-size: 16px;
  color: #484747;
  position: absolute;
  white-space: nowrap;
  left: 0px;
  right: 0px;
  margin: 15px auto 0px;
}

.work_step li:nth-child(2) p {
  left: -8px;
}

.work_step li:nth-child(5) p {
  left: -25px;
}

.ship_vendors {
  margin-top: 175px;
  position: relative;
}

.ship_vendors:after {
  position: absolute;
  content: "";
  height: calc(100% + 310px);
  width: 1px;
  border-left: 1px dashed rgba(152, 213, 248, 0.3);
  top: -232px;
  left: 0px;
  right: 0px;
  margin: auto;
}

.ship_owners_outer,
.vndors_outer {
  width: 50%;
}

.ship_owners,
.vndors {
  width: 454px;
  height: 347px;
  margin-left: 42px;
  background-image: url(./assets/images/ship_vendors_bg.png);
  position: relative;
}

.vndors {
  margin-left: 86px;
  margin-top: 19px;
}

.ship_vendors h3 {
  font-weight: 600;
  color: #2d303f;
  font-size: 23px;
  text-transform: uppercase;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.ship_ven_point li,
.ven_point li {
  position: absolute;
  text-align: center;
}

.ship_ven_point p,
.ven_point p {
  color: #484747;
  line-height: 20px;
  margin-top: 5px;
}

.ship_ven_point li:first-child {
  margin-top: 134px;
  margin-left: -40px;
}

.ship_ven_point li:first-child p {
  margin-left: -5px;
  margin-top: 10px;
}

.ship_ven_point li:nth-child(2) {
  margin-top: 9px;
  margin-left: 15px;
}

.ship_ven_point li:nth-child(2) img {
  margin-left: 10px;
}

.ship_ven_point li:nth-child(2) p {
  margin-left: -20px;
  margin-top: 10px;
}

.ship_ven_point li:nth-child(3) {
  margin-top: -35px;
  margin-left: 183px;
}

.ship_ven_point li:nth-child(4) {
  margin-top: 9px;
  margin-left: 355px;
}

.ship_ven_point li:nth-child(5) {
  margin-top: 151px;
  margin-left: 388px;
}

.ship_ven_point li:nth-child(5) img {
  margin-left: 35px;
}

.ship_ven_point li:nth-child(5) p {
  margin-right: -39px;
}

.ship_ven_point li:nth-child(6) {
  margin-top: 309px;
  margin-left: 229px;
}

.ship_ven_point li:nth-child(6) p {
  margin-top: 20px;
  margin-left: -5px;
}

.ship_ven_point li:nth-child(7) {
  margin-top: 305px;
  margin-left: 58px;
}

.ship_ven_point li:nth-child(7) p {
  margin-left: -6px;
  margin-top: 0px;
}

.ven_point li:first-child {
  margin-top: 136px;
  margin-left: -40px;
}

.ven_point li:first-child p {
  margin-top: 13px;
  margin-left: -8px;
}

.ven_point li:nth-child(2) {
  margin-left: -33px;
  margin-top: 6px;
}

.ven_point li:nth-child(2) img {
  margin-left: 31px;
}

.ven_point li:nth-child(2) p {
  margin-top: 9px;
}

.ven_point li:nth-child(3) {
  margin-left: 132px;
  margin-top: -26px;
}

.ven_point li:nth-child(3) p {
  margin-top: 0px;
}

.ven_point li:nth-child(4) {
  margin-left: 331px;
  margin-top: 27px;
}

.ven_point li:nth-child(4) p {
  margin-top: 10px;
}

.ven_point li:nth-child(5) {
  margin-left: 354px;
  margin-top: 128px;
}

.ven_point li:nth-child(5) img {
  margin-left: 72px;
}

.ven_point li:nth-child(5) p {
  margin-right: -53px;
  margin-top: 10px;
}

.ven_point li:nth-child(6) {
  margin-left: 268px;
  margin-top: 302px;
}

.ven_point li:nth-child(6) p {
  margin-top: 10px;
  margin-left: 7px;
}

.ven_point li:nth-child(7) {
  margin-left: 100px;
  margin-top: 298px;
}

.ven_point li:nth-child(7) p {
  margin-top: 10px;
  margin-left: -6px;
}

.icon_round_center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: #fff;
  padding: 8px 0px;
  z-index: 1;
}

.work_bottom {
  margin-top: 90px;
}

.online_chat_room {
  margin-top: 13px;
}

.online_chat_room a {
  color: #484747;
}

.online_chat_room a:hover {
  color: #ff5757;
}

.online_chat_room img {
  display: block;
  margin: auto auto 11px;
}

.benifits_bg {
  margin-top: 27px;
  padding: 57px 0px;
  background: url(./assets/images/benifits_bg.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.benefit {
  margin-top: 60px;
}

.benefit_inner {
  text-align: center;
}

.benefit_img {
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.benefit_img img {
  max-width: 100%;
  max-height: 100%;
  filter: drop-shadow(6px 0px 10px rgba(0, 0, 0, 0.21));
}

.benefit_text p {
  padding: 0px 15px;
  font-size: 16px;
  color: #484747;
  line-height: 26px;
  margin: 40px 0px 30px;
}

.home_contact_bg {
  margin-top: 7px;
  padding: 110px 0px 0px;
  background: url(./assets/images/contact_bg.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  height: 685px;
}

.mb-70 {
  margin-bottom: 70px;
}

.title p {
  max-width: 812px;
  margin: auto;
  font-size: 20px;
  line-height: 28px;
  margin-top: 20px;
}

.contact_outer {
  margin-top: 65px;
  align-items: flex-start;
  align-content: flex-start;
}

.address_box {
  width: 185px;
  background: #fff;
  border-radius: 3px;
  padding: 25px 22px;
  margin-left: 13px;
}

.address_box p {
  font-size: 16px;
  font-weight: 500;
  line-height: 26px;
}

.address img {
  max-width: 100%;
  max-height: 100%;
}

.address {
  margin-top: 100px;
  margin-left: 125px;
}

.contact_box {
  width: 560px;
  background: #fff;
  padding: 60px 40px;
  border-radius: 30px;
  margin: auto 0px auto auto;
  box-shadow: 10px 0px 50px rgba(0, 0, 0, 0.1);
}

.form-control {
  -webkit-box-shadow: none !important;
  -moz-box-shadow: none !important;
  box-shadow: none !important;
}

.contact_box .form-control {
  background: #f2f3f8;
  border: 0px !important;
  color: #818897;
  font-size: 14px;
  height: 50px;
  padding: 0px 20px;
}

.contact_box textarea.form-control {
  padding: 20px;
  height: 100px;
}

.contact_box .form-group {
  margin-bottom: 17px;
}

.contact_box .btn {
  margin-top: 21px;
}

.mxy-7 {
  margin: 0px -7px;
}

.pxy-7 {
  padding: 0px 7px;
}

.footer_top {
  border-top: 1px solid #e5e5e5;
  padding: 70px 0px 75px;
}

.footer_top h4 {
  color: #2c2f33;
  font-size: 14px;
  font-weight: 500;
  text-transform: uppercase;
  margin-bottom: 25px;
}

.footer_menu {
  columns: 2;
  -webkit-columns: 2;
  -moz-columns: 2;
  -webkit-column-gap: 95px;
  -moz-column-gap: 95px;
  column-gap: 95px;
}

.footer_menu li {
  margin-bottom: 15px;
}

.footer_menu li a {
  color: #555555;
}

.footer_menu li a:hover {
  color: #ff5757;
}

.footer_contact_info li {
  margin-bottom: 15px;
}

.footer_contact_info li a {
  color: #555555;
}

.footer_contact_info li a:hover {
  color: #ff5757;
}

.footer_contact_icon {
  width: 33px;
}

.footer_news_outer {
  position: relative;
}

.footer_news_outer .form-control {
  width: 360px;
  height: 50px;
  border: 0px !important;
  color: #939393;
  font-size: 14px;
  padding: 0px 65px 0px 20px;
  box-shadow: 7px 0px 21px rgba(0, 0, 0, 0.11) !important;
}

.footer_news_outer .btn {
  position: absolute;
  right: 0px;
  top: 0px;
  height: 50px;
  background: #03953f;
  width: 60px;
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
}

.footer_news_outer .btn:hover {
  background: #01672b;
}

.footer_bottom {
  border-top: 1px solid #ddd;
  padding: 45px 0px;
}

.footer_bottom p {
  color: #000000;
}

.footer_bottom a {
  color: #000000;
  margin-left: 5px;
  display: inline-block;
}

.footer_bottom a:hover {
  color: #ff5757;
}

.toggle_menu {
  display: none;
}

.toggle_menu label {
  margin-bottom: 0px;
  line-height: 23px;
  font-size: 23px;
  color: #fff;
  cursor: pointer;
  -moz-transform: scale(1.2, 1);
  -webkit-transform: scale(1.2, 1);
  transform: scale(1.2, 1);
}

.closeMenu {
  position: absolute;
  top: 10px;
  cursor: pointer;
  color: #fff;
  height: 30px;
  width: 30px;
  border: 1px solid #ddd;
  font-size: 20px;
  display: none;
  align-items: center;
  justify-content: center;
}

/*------------------ Home Page Css End ------------------*/
@media (min-width: 992px) and (max-width: 1199px) {
  /*------------------ Home Page Css Start ------------------*/
  .ship_owners_outer,
  .vndors_outer {
    width: 100%;
  }

  .ship_vendors:after {
    content: none;
  }

  .ship_vendors {
    margin-top: 75px;
  }

  .ship_owners,
  .vndors {
    margin: auto;
  }

  .vndors {
    margin: 150px auto 0px;
  }

  .icon_round_center {
    display: none;
  }

  .benefit_text p {
    padding: 0px;
  }

  /*------------------ Home Page Css End ------------------*/
}

@media (min-width: 768px) and (max-width: 991px) {
  /*------------------ Home Page Css Start ------------------*/
  nav li a {
    margin-right: 30px;
  }

  .header_login a:after {
    left: -19px;
  }

  .banner {
    min-height: 650px;
    padding-top: 135px;
  }

  .banner h1 {
    font-size: 35px;
    line-height: 39px;
  }

  .banner p {
    font-size: 17px;
    margin-top: 16px;
  }

  .ship_owners_outer,
  .vndors_outer {
    width: 100%;
  }

  .sign_me_up {
    margin: -120px 15px 0px;
  }

  .work_step li {
    width: 33.33%;
  }

  .work_step li p {
    position: relative;
    margin: 15px auto 25px;
    left: 0px !important;
  }

  .work_step ul:after,
  .ship_vendors:after {
    content: none;
  }

  .ship_vendors {
    margin-top: 75px;
  }

  .ship_owners,
  .vndors {
    margin: auto;
  }

  .vndors {
    margin: 150px auto 0px;
  }

  .icon_round_center {
    display: none;
  }

  .benefit_text p {
    font-size: 14px;
    line-height: 23px;
    padding: 0px;
  }

  .address {
    margin-left: 0px;
  }

  .contact_box {
    width: 420px;
    padding: 20px;
    border-radius: 15px;
  }

  .home_contact_bg {
    height: 625px;
  }

  .footer_menu {
    -webkit-column-gap: 15px;
    -moz-column-gap: 15px;
    column-gap: 15px;
  }

  .company {
    width: 190px;
  }

  .footer_news {
    width: 270px;
  }

  .footer_news .form-control {
    width: 100%;
  }

  /*------------------ Home Page Css End ------------------*/
}

@media (min-width: 576px) and (max-width: 767px) {
  /*------------------ Home Page Css Start ------------------*/
  .logo img {
    width: 103px;
  }

  .header_right {
    position: fixed;
    top: 0px;
    right: -300px;
    background: #ff5757;
    width: 280px;
    height: 100%;
    overflow: hidden;
    overflow-y: auto;
    display: block !important;
    padding: 50px 20px 20px;
    -webkit-transition: all 0.3s linear;
    -moz-transition: all 0.3s linear;
    transition: all 0.3s linear;
  }

  .header_right.active {
    right: 0px;
  }

  .toggle_menu {
    display: block;
  }

  .closeMenu {
    display: flex;
  }

  nav li {
    display: block;
    border-bottom: 1px solid rgba(255, 255, 255, 0.3);
    padding: 10px 5px;
  }

  .header_login {
    margin-left: 5px;
    margin-top: 10px;
  }

  .header_login a:after {
    content: none;
  }

  .header_login a:hover,
  nav li a:hover {
    color: #fff;
  }

  .banner h1 {
    font-size: 25px;
    line-height: 33px;
  }

  .banner {
    min-height: 450px;
    padding-top: 90px;
  }

  .banner p {
    font-size: 14px;
    margin-top: 7px;
  }

  .sign_me_up {
    padding: 25px;
    margin: 20px 15px 0px;
    border-radius: 10px;
  }

  .sign_me_up > div {
    display: block !important;
    text-align: center;
  }

  .sign_me_up p {
    width: 100%;
    font-size: 18px;
    line-height: 30px;
    margin-bottom: 15px;
  }

  .btn-blue {
    font-size: 13px;
    padding: 9px 18px;
  }

  .work_step ul:after,
  .ship_vendors:after {
    content: none;
  }

  .work_step li {
    width: 33.33%;
  }

  .how_it_works {
    margin-top: 30px;
  }

  .title h3 {
    font-size: 23px;
  }

  .work_step li p {
    font-size: 14px;
    position: relative;
    white-space: initial;
    margin: 15px auto 15px;
    left: 0px !important;
  }

  .work_step {
    margin-top: 25px;
  }

  .work_bottom {
    margin-top: 30px;
  }

  .ship_vendors {
    margin-top: 30px;
  }

  .ship_owners_outer,
  .vndors_outer {
    width: 100%;
  }

  .ship_owners,
  .vndors {
    width: 100%;
    height: initial;
    margin-left: 0px;
    background: none;
    position: relative;
  }

  .vndors {
    margin-left: 0px;
    margin-top: 30px;
  }

  .ship_vendors h3 {
    font-size: 17px;
    position: relative;
    top: 0px;
    left: 0px;
    transform: translate(0%, 0%);
    text-align: center;
    margin-bottom: 25px;
  }

  .ship_ven_point,
  .ven_point {
    display: flex;
    flex-wrap: wrap;
  }

  .ship_ven_point li,
  .ven_point li {
    width: 33.33%;
    position: relative;
    margin-top: 0px !important;
    margin-left: 0px !important;
    border-left: 1px solid #eee;
    border-bottom: 1px solid #eee;
    padding: 10px 4px;
  }

  .ship_ven_point li:nth-child(3n + 1),
  .ven_point li:nth-child(3n + 1) {
    border-left: 0px;
  }

  .ship_ven_point li:last-child,
  .ven_point li:last-child {
    width: 100%;
  }

  .ship_ven_point li img,
  .ven_point li img {
    margin-left: 0px !important;
  }

  .ship_ven_point li p,
  .ven_point li p {
    margin: 10px 0px 0px !important;
    font-size: 12px;
    line-height: 19px;
  }

  .icon_round_center {
    display: none;
  }

  .benifits_bg {
    padding: 30px 0px;
  }

  .benefit {
    margin-top: 30px;
  }

  .benefit_text p {
    padding: 0px;
    font-size: 14px;
    line-height: 24px;
    margin: 20px 0px 25px;
  }

  .home_contact_bg {
    padding: 30px 0px 0px;
    height: initial;
  }

  .mb-70 {
    margin-bottom: 40px;
  }

  .title p {
    font-size: 13px;
    line-height: 22px;
    margin-top: 10px;
  }

  .contact_outer {
    margin-top: 30px;
  }

  .address {
    margin-top: 0px;
    margin-left: 0px;
  }

  .address_box {
    width: calc(100% - 35px);
    padding: 15px 15px;
  }

  .address_box p {
    font-size: 14px;
    line-height: 21px;
  }

  .contact_box {
    width: 100%;
    margin-top: 20px;
    padding: 15px;
    border-radius: 10px;
  }

  .contact_box .btn {
    margin-top: 0px;
  }

  .footer_top {
    padding: 30px 0px;
  }

  .company,
  .footer_contact {
    width: 50%;
  }

  .footer_news {
    margin-top: 25px;
    width: 100%;
  }

  .footer_top h4 {
    margin-bottom: 20px;
  }

  .footer_news_outer .form-control {
    width: 100%;
  }

  .footer_bottom {
    padding: 20px 0px;
  }

  .footer_menu {
    -webkit-column-gap: 15px;
    -moz-column-gap: 15px;
    column-gap: 15px;
  }

  /*------------------ Home Page Css End ------------------*/
}

@media (max-width: 575px) {
  /*------------------ Home Page Css Start ------------------*/
  .logo img {
    max-width: 100%;
    display: inline-block;
    height: auto;
    margin-left: 10px !important;
  }

  .header_right {
    position: fixed;
    top: 0px;
    right: -300px;
    background: #ff5757;
    width: 280px;
    height: 100%;
    overflow: hidden;
    overflow-y: auto;
    display: block !important;
    padding: 50px 20px 20px;
    -webkit-transition: all 0.3s linear;
    -moz-transition: all 0.3s linear;
    transition: all 0.3s linear;
  }

  .header_right.active {
    right: 0px;
  }

  .toggle_menu {
    display: block;
  }

  .closeMenu {
    display: flex;
  }

  nav li {
    display: block;
    border-bottom: 1px solid rgba(255, 255, 255, 0.3);
    padding: 10px 5px;
  }

  .header_login {
    margin-left: 5px;
    margin-top: 10px;
  }

  .header_login a:after {
    content: none;
  }

  .header_login a:hover,
  nav li a:hover {
    color: #fff;
  }

  .banner h1 {
    font-size: 19px;
    line-height: 27px;
  }

  .banner {
    min-height: 450px;
    padding-top: 90px;
  }

  .banner p {
    font-size: 12px;
    padding: 4px 10px;
    margin-top: 7px;
  }

  .sign_me_up {
    padding: 15px;
    margin: 20px 15px 0px;
    border-radius: 10px;
  }

  .sign_me_up > div {
    display: block !important;
    text-align: center;
  }

  .sign_me_up p {
    width: 100%;
    font-size: 16px;
    line-height: 27px;
    margin-bottom: 15px;
  }

  .btn-blue {
    font-size: 13px;
    padding: 9px 18px;
  }

  .work_step ul:after,
  .ship_vendors:after {
    content: none;
  }

  .work_step li {
    width: 50%;
  }

  .how_it_works {
    margin-top: 30px;
  }

  .title h3 {
    font-size: 23px;
  }

  .work_step li p {
    font-size: 14px;
    position: relative;
    white-space: initial;
    margin: 15px auto 15px;
    left: 0px !important;
  }

  .work_step {
    margin-top: 25px;
  }

  .work_bottom {
    margin-top: 30px;
  }

  .ship_vendors {
    margin-top: 30px;
  }

  .ship_owners_outer,
  .vndors_outer {
    width: 100%;
  }

  .ship_owners,
  .vndors {
    width: 100%;
    height: initial;
    margin-left: 0px;
    background: none;
    position: relative;
  }

  .vndors {
    margin-left: 0px;
    margin-top: 30px;
  }

  .ship_vendors h3 {
    font-size: 17px;
    position: relative;
    top: 0px;
    left: 0px;
    transform: translate(0%, 0%);
    text-align: center;
    margin-bottom: 25px;
  }

  .ship_ven_point,
  .ven_point {
    display: flex;
    flex-wrap: wrap;
  }

  .ship_ven_point li,
  .ven_point li {
    width: 50%;
    position: relative;
    margin-top: 0px !important;
    margin-left: 0px !important;
    border-left: 1px solid #eee;
    border-bottom: 1px solid #eee;
    padding: 10px 4px;
  }

  .ship_ven_point li:nth-child(2n + 1),
  .ven_point li:nth-child(2n + 1) {
    border-left: 0px;
  }

  .ship_ven_point li:last-child,
  .ven_point li:last-child {
    width: 100%;
  }

  .ship_ven_point li img,
  .ven_point li img {
    margin-left: 0px !important;
  }

  .ship_ven_point li p,
  .ven_point li p {
    margin: 10px 0px 0px !important;
    font-size: 12px;
    line-height: 19px;
  }

  .icon_round_center {
    display: none;
  }

  .benifits_bg {
    padding: 30px 0px;
  }

  .benefit {
    margin-top: 30px;
  }

  .benefit_text p {
    padding: 0px;
    font-size: 14px;
    line-height: 24px;
    margin: 20px 0px 25px;
  }

  .home_contact_bg {
    padding: 30px 0px 0px;
    height: initial;
  }

  .mb-70 {
    margin-bottom: 40px;
  }

  .title p {
    font-size: 13px;
    line-height: 22px;
    margin-top: 10px;
  }

  .contact_outer {
    margin-top: 30px;
  }

  .address {
    margin-top: 0px;
    margin-left: 0px;
  }

  .address_box {
    width: calc(100% - 35px);
    padding: 15px 15px;
  }

  .address_box p {
    font-size: 14px;
    line-height: 21px;
  }

  .contact_box {
    width: 100%;
    margin-top: 20px;
    padding: 15px;
    border-radius: 10px;
  }

  .contact_box .btn {
    margin-top: 0px;
  }

  .footer_top {
    padding: 30px 0px;
  }

  .company {
    width: 100%;
  }

  .footer_contact,
  .footer_news {
    margin-top: 25px;
    width: 100%;
  }

  .footer_top h4 {
    margin-bottom: 20px;
  }

  .footer_news_outer .form-control {
    width: 100%;
  }

  .footer_bottom {
    padding: 20px 0px;
  }

  .footer_bottom .d-align-new > p {
    width: 100%;
    text-align: center;
  }

  .footer_bottom_right {
    width: 100%;
    justify-content: center;
    margin-top: 10px;
  }

  /*------------------ Home Page Css End ------------------*/
}

/******************* Modified *********************/

.common-section-header {
  min-height: 75px;
  padding: 20px 0 0;
}

.secondary-header-content {
  position: relative;

  .left-image {
    float: left;
    width: 40%;
    text-align: right;

    img {
      max-width: 100%;
      height: auto;
    }
  }

  .right-title {
    width: 60%;
    position: absolute;
    left: 40%;
    top: 50%;

    -ms-transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    text-align: left;

    h1 {
      background-color: #ff5757;
      color: #ffffff;
      padding: 15px 30px;
      border-radius: 20px;
      font-size: 18px;
      display: inline-block;
      min-width: 300px;
      text-align: center;
    }
  }

  .lr-title h1 {
    padding: 17px 30px;
    margin-top: 45px;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
}

.lrborder {
  position: relative;
  padding-right: 8px;
  overflow: hidden;

  .loginRightBorder {
    border-right: 1.5px dotted #ff5757;
    overflow: inherit;
    height: 100%;
    //  display: flex;
    //  align-items: center;
  }
}

.lrborder::after {
  content: "";
  height: 18px;
  width: 18px;
  background-color: #ff5757;
  display: block;
  border-radius: 100%;
  right: 0;
  position: absolute;
  bottom: 0;
}

.lrborder::before {
  content: "";
  height: 18px;
  width: 18px;
  background-color: #ff5757;
  display: block;
  border-radius: 100%;
  right: 0;
  position: absolute;
  top: 0;
}

.pb40 {
  padding: 50px 0;
}

.pb60 {
  padding: 60px 0;
}

.icon-bookrl {
  position: absolute;
  top: calc(50% - 20px);
  left: -40px;
  width: 40px;
  height: 40px;
}

.icon-bookrl img {
  max-width: 100%;
  height: auto;
}

.steper-position .ant-steps-item-process .ant-steps-item-icon {
  background: #ff5757 !important;
}

.steper-position .ant-steps-item-wait .ant-steps-item-icon,
.steper-position .ant-steps-item-process .ant-steps-item-icon {
  border: 3px solid rgb(194, 202, 245);
  line-height: 29px;
  font-size: 12px;
}

.ant-steps-item-wait .ant-steps-item-icon > .ant-steps-icon {
  color: rgb(50, 78, 228);
  font-weight: 500;
  font-family: "Montserrat";
}

.steper-position
  .ant-steps-horizontal:not(.ant-steps-label-vertical)
  .ant-steps-item {
  margin-right: 0px;
}

.steper-position
  .ant-steps-horizontal:not(.ant-steps-label-vertical)
  .ant-steps-item::after {
  content: "";
  width: calc(100% - 35px);
  display: block;
  height: 2px;
  border: 1px dashed #5870f5;
  float: right;
  margin-top: -15px;
  margin-right: 3px;
}

.steper-position
  .ant-steps-horizontal:not(.ant-steps-label-vertical)
  .ant-steps-item:last-child::after {
  display: none;
}

.notification-container {
  width: 225px;
  max-height: 300px;
  overflow-y: auto;
}

nz-card {
  border-radius: 0.5rem !important;
}

.ant-layout-header {
  background: #edecec;
  padding: 0 0 0 0 !important;
}

.ant-menu-dark {
  background: transparent;
  color: #232323;

  .ant-menu-sub {
    background: #edecec;
    color: #232323;
  }

  .ant-menu-item {
    color: #232323;
  }
}

.ant-menu-dark .ant-menu-item:hover,
.ant-menu-dark .ant-menu-item-active,
.ant-menu-dark .ant-menu-submenu-active,
.ant-menu-dark .ant-menu-submenu-open,
.ant-menu-dark .ant-menu-submenu-selected,
.ant-menu-dark .ant-menu-submenu-title:hover {
  color: #ff5757;
}

.ant-steps-item-wait {
  .ant-steps-item-icon > .ant-steps-icon {
    color: #ff5757;
  }
}

footer {
  .container {
    padding: 40px;
    margin-bottom: 25px;

    .leftsidemenu {
      width: 60%;
      float: left;
      text-align: right;
      padding-right: 20px;
      font-size: 14px;
      color: #e1525f;
      padding-top: 5px;
    }

    .leftsidemenu a {
      color: #e1525f;
      text-decoration: none;
    }

    .leftsidemenu a:hover {
      color: #000;
    }

    .rightsocialmedoa {
      width: 40%;
      float: right;
      text-align: left;
      padding-left: 20px;
    }

    .rightsocialmedoa ul {
      margin: 0px auto;
      padding: 0px;
    }

    .rightsocialmedoa ul li {
      list-style: none;
      display: inline-block;
      padding-right: 5px;
    }

    .rightsocialmedoa ul li span {
      color: #e1525f;
      margin-right: 15px;
    }

    .rightsocialmedoa ul li a {
      width: 30px;
      height: 30px;
      background-color: #e1525f;
      color: #fff;
      line-height: 30px;
      text-align: center;
      display: block;
      border-radius: 100%;
    }

    .rightsocialmedoa ul li:hover a {
      background-color: #000;
    }
  }
}

@media only screen and (max-width: 1000px) {
  .leftsidemenu {
    width: 100% !important;
    text-align: center !important;
    padding: 0 !important;
  }

  .rightsocialmedoa {
    width: 100% !important;
    text-align: center !important;
    margin-top: 12px !important;
    padding: 0 !important;
  }
}

nz-layout {
  .inner-layout {
    // background: #ccc;
  }
}

.ant-layout-sider-trigger {
  color: #ff5757 !important;
  font-size: 30px;
}

// login and signUp title
.displayTitle {
  display: inline-block;
  margin-right: 10px;
  margin-bottom: 20px;
}

.ant-table-thead > tr > th,
.ant-table-tbody > tr > td {
  text-align: center;
}

// bidding component css Start
.biddingCard {
  min-height: 110px;
  box-sizing: border-box;
  border-radius: 30px !important;
  box-shadow: 0 20px 15px 0 rgba(201, 200, 200, 0.32);
  height: 100%;
}

.biddingCardAudit {
  min-height: 110px;
  box-sizing: border-box;
  border-radius: 30px !important;
  box-shadow: 0 20px 15px 0 rgba(201, 200, 200, 0.32);
}
.row {
  margin-bottom: 15px;
}

.leftBorder {
  position: relative;
  border-left: 1.5px solid #c9ced6;
  overflow: inherit;
  height: 100%;
  padding-left: 20px;
}

.flexbx {
  display: flex;
  align-items: center;
}

// bidding component end

// Icon Fix
.anticon {
  vertical-align: 0 !important;
}

.ant-rate {
  color: black;
}

.flex-align-center {
  align-items: center;
}

.loading-spinner {
  text-align: center;
  background: rgb(240 242 245);
  border-radius: 4px;
  padding: 30px 50px;
  margin: 20px 0;
  height: 80vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
.resubmission-alert {
  margin-bottom: 24px;
}
.padding-right-27 {
  padding-right: 27px;
}

.additionalcost {
  margin-top: 10px;
  .ant-form-item {
    margin-bottom: 0px;
  }
}

// sajal works for responsive
.usericon-header {
  transform: scale(1) translateX(-50%);
}

@media only screen and (max-width: 1024px) {
  .mb-10 {
    margin-bottom: 10px;
  }
  .mob-mb15 {
    margin-bottom: 15px;
  }
}

@media only screen and (max-width: 500px) {
  .ant-steps-horizontal.ant-steps-label-horizontal {
    display: block;
  }
  .ant-steps-horizontal.ant-steps-label-horizontal .ant-steps-item {
    display: block;
    overflow: visible;
    padding: 5px 10px 5px 0;
  }
  .ant-steps-horizontal.ant-steps-label-horizontal .ant-steps-item:last-child {
    margin-bottom: 15px;
  }
  .ant-steps-horizontal.ant-steps-label-horizontal
    > .ant-steps-item
    > .ant-steps-item-container
    > .ant-steps-item-tail {
    position: absolute;
    top: 5px;
    left: 16px;
    width: 1px;
    height: 100%;
    padding: 38px 0 6px;
    background-color: #c9c9c9;
    z-index: 1;
  }
  .ant-steps-horizontal.ant-steps-label-horizontal
    > .ant-steps-item:not(:last-child)
    > .ant-steps-item-container
    > .ant-steps-item-tail {
    display: block;
  }
  .ant-steps-item-icon {
    position: relative;
    z-index: 2;
  }
  .ant-steps-item-title::after {
    display: none;
  }
  .customheadergap {
    padding: 16px 0;
  }

  .padding-right-27 {
    padding-right: 0px;
  }
}

.ant-tabs-tab {
  margin: 0 32px 0 0;
}

.ant-row {
  margin-right: 0;
  margin-left: 0;
  position: relative;
}

.header-search-input {
  padding: 4.5px 11px;
}
.ant-page-header {
  padding: 12px 24px;
}

.ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal) .ant-menu-item-selected {
  color: #fff !important;
}
.ant-breadcrumb > nz-breadcrumb-item:first-child a {
  color: #ff5757 !important;
}

.ant-divider-horizontal {
  margin: 20px 0;
}

.ant-table.ant-table-bordered
  > .ant-table-container
  > .ant-table-content
  > table
  > thead
  > tr
  > th {
  border-right: 0px solid #e8e8e8 !important;
}
.ant-table.ant-table-bordered
  > .ant-table-container
  > .ant-table-content
  > table
  > tbody
  > tr
  > td {
  border-right: 0px solid #e8e8e8 !important;
}
.ant-table-thead > tr > th {
  border-bottom: 0px solid #e8e8e8 !important;
}

.ant-table-tbody > tr > td {
  border-bottom: 0px solid #e8e8e8 !important;
  background-color: #fff !important;
}

.ant-table.ant-table-bordered
  > .ant-table-container
  > .ant-table-content
  > table {
  border-top: 0px solid #e8e8e8 !important;
}
.ant-table.ant-table-bordered > .ant-table-container {
  border-left: 0px solid !important;
}

@media only screen and (max-width: 1000px) {
  nz-result {
    width: 100% !important;
  }
  app-global-search {
    width: 100%;
  }
  .outerdiv {
    max-width: 100vw;
    overflow-x: auto;
  }
}
.inner-search {
  width: 1000px;
}
@media (max-width: 992px) {
  .scroll800-900 {
    width: 100%;
    overflow: scroll;
    .ant-table-container {
      width: 800px;
    }
  }
  .scroll800-1100 {
    width: 100%;
    overflow: scroll;
    .ant-table-container {
      width: 1100px;
    }
  }
  .scroll800-1200 {
    width: 100%;
    overflow: scroll;
    .ant-table-container {
      width: 1200px;
    }
  }
  .mb-15-mob {
    margin-bottom: 15px;
  }
}

.mb-12 {
  margin-bottom: 12px;
}
h5.fileuploaded {
  margin-bottom: 12px;
}
.ant-avatar-string {
  transform: scale(1) translateX(-50%);
}

.ant-table-thead > tr > th,
.ant-table-tbody > tr > td,
.ant-table tfoot > tr > th,
.ant-table tfoot > tr > td {
  padding: 16px 8px;
}

.header-menu {
  align-items: center;
  height: 100%;
  .smallNotificationButtons {
    padding: 0px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    margin-top: 4px !important;
  }
}
.floating-chat-container
  .ant-collapse
  > .ant-collapse-item
  > .ant-collapse-header {
  padding: 8px 15px 3px;
}
.border-radius-30 {
  border-radius: 30px !important;
}
@media (max-width: 350px) {
  .logo {
    width: 90px !important;
    img {
      margin-left: 10px !important;
    }
  }
  .trigger {
    padding: 0 6px !important;
  }
}

.steps-info {
  color: #ff5757;
}
